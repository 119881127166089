import { Pagination, Select } from 'antd';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Apis from '../../../../../api';
import { HEADERMENU_PATH } from '../../../../../routes/RoutesURL';
import { RootState } from '../../../../../store/RootReducer';
import { commaSeperator } from '../../../../../utils/Validation';
import PButton from '../../../../common/Button';
import PCard from '../../../../common/Card';
import PTable from '../../../../common/Table';
import MessageActions from '../../../../message/redux/actions';
import OverAllReportAction from '../redux/actions';

type Props = {
    startDate: any;
    endDate: any;
};

const CapmaignPerformanceTable: React.FC<Props> = ({ startDate, endDate }) => {
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedSite, setSelectedSite] = useState<string | undefined>('');
    const navigate = useNavigate();
    const totalRecords = useSelector((state: RootState) => state.report.totalCampeignListRecords);
    const campList = useSelector((state: RootState) => state.report.reportsCampeignList);
    const campListLoader = useSelector((state: RootState) => state.report.reportsCampeignListLoader);
    const siteList = useSelector((state: RootState) => state.report.reportsAllCampSitesList);
    
    const [downloadLoader, setDownloadLoader] = useState<boolean>(false);
    const columnData = [
        {
            title: 'Campaign Name',
            dataIndex: 'campaign_name',
            width: 280,
            fixed: 'left',
            render: (text: string, row: any) => (
                <div
                    className="underline"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`${HEADERMENU_PATH.overallReport}/${row.campaign_id}`, { state: { name: text } });
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: 'Budget Spent',
            dataIndex: 'budget_spent',
            render: (text: any) => <div className="flex justify-end">${commaSeperator(parseFloat(text).toFixed(2))}</div>,
        },
        {
            title: 'eCPM',
            dataIndex: 'eCPM',
            render: (text: any) => <div className="flex justify-end">${commaSeperator(parseFloat(text).toFixed(2))}</div>,
        },
        {
            title: 'Click Through Rate(CTR)',
            dataIndex: 'CTR',
            render: (text: any) => <div className="flex justify-end">{parseFloat(text).toFixed(2)}%</div>,
        },
        {
            title: 'Ad Impressions',
            dataIndex: 'impressions',
            render: (text: any) => <div className="flex justify-end">{commaSeperator(text)}</div>,
        },
    ];

    useEffect(() => {
        if (pageNo && pageSize && startDate && endDate) {
            const payload = {
                start_dt: startDate,
                end_dt: endDate,
                site_name: selectedSite,
            };
            dispatch(OverAllReportAction.fetchReportAllCampSites(payload));
            dispatch(OverAllReportAction.fetchReportCampaigns(pageNo, pageSize, payload));
        }
        // 
    }, [dispatch, pageNo, pageSize, startDate, endDate, selectedSite]);

     // Handle Site Change
     const handleSiteChange = (value: string) => {
        setSelectedSite(value);
    };

    const handleCampaignPerformanceDownload = () => {
        setDownloadLoader(true);
        const payload = {
            start_dt: startDate,
            end_dt: endDate,
            site_name: selectedSite,
        };

        Apis.exportCampaignPerformance(payload)
            .then(({ data }) => {
                download(data, 'Campaign Performance Report.xlsx');
            })
            .catch((err) => {
                dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
            })
            .finally(() => {
                setDownloadLoader(false);
            });
    };
    return (
        <div className="">
            {/* <div className="flex justify-between">
                <div className="text-[18px] font-[700] font-[Roboto]">Campaign Performances</div>
                <div>
                    <PButton loading={downloadLoader} className="" title={'Export'} onClick={handleCampaignPerformanceDownload} />
                </div>
            </div> */}
            <div className="flex justify-between" style={{marginTop: '10px',}}>
                <Select
                    className="w-48"
                    placeholder="Select Site"
                    onChange={handleSiteChange}
                    value={selectedSite}
                    options={[
                        { label: 'All', value: '' },
                        ...(siteList?.map((site: any) => ({
                            label: site.name, value: site.name,
                        })) || [])
                    ]}
                />
            </div>
            <PCard>
                <PTable loading={campListLoader} columns={columnData} data={campList || []} className="mt-2" rowKey={(c: any) => c._id} />
                <div className="flex justify-end py-2">
                    <Pagination
                        current={pageNo}
                        total={totalRecords}
                        onChange={(e) => setPageNo(e)}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                        showSizeChanger={true}
                        onShowSizeChange={(e, i) => setPageSize(i)}
                        pageSizeOptions={[10, 20, 50, 100]}
                    />
                </div>
            </PCard>
        </div>
    );
};

export default CapmaignPerformanceTable;
