const reportTypes = {
     FETCH_REPORT_TOPCARDS: 'FETCH_REPORT_TOPCARDS',
     SET_REPORT_TOPCARDS: 'SET_REPORT_TOPCARDS',

     FETCH_REPORTS_BUGDET_SPEND_GRAPH: 'FETCH_REPORTS_BUGDET_SPEND_GRAPH',
     SET_REPORTS_BUGDET_SPEND_GRAPH: 'SET_REPORTS_BUGDET_SPEND_GRAPH',

     FETCH_REPORTS_CTR_GRAPH: 'FETCH_REPORTS_CTR_GRAPH',
     SET_REPORTS_CTR_GRAPH: 'SET_REPORTS_CTR_GRAPH',

     FETCH_REPORTS_IMP_CLICKS_GRAPH: 'FETCH_REPORTS_IMP_CLICKS_GRAPH',
     SET_REPORTS_IMP_CLICKS_GRAPH: 'SET_REPORTS_IMP_CLICKS_GRAPH',

     FETCH_REPORTS_TOP_COUNTRIES_DATA_GRAPH: 'FETCH_REPORTS_TOP_COUNTRIES_DATA_GRAPH',
     SET_REPORTS_TOP_COUNTRIES_DATA_GRAPH: 'SET_REPORTS_TOP_COUNTRIES_DATA_GRAPH',

     FETCH_REPORTS_TOP_DEVICES_DATA_GRAPH: 'FETCH_REPORTS_TOP_DEVICES_DATA_GRAPH',
     SET_REPORTS_TOP_DEVICES_DATA_GRAPH: 'SET_REPORTS_TOP_DEVICES_DATA_GRAPH',

     FETCH_REPORTS_TOP_MEDIASIZES_DATA_GRAPH: 'FETCH_REPORTS_TOP_MEDIASIZES_DATA_GRAPH',
     SET_REPORTS_TOP_MEDIASIZES_DATA_GRAPH: 'SET_REPORTS_TOP_MEDIASIZES_DATA_GRAPH',

     FETCH_REPORTS_TOP_SITES_DATA_GRAPH: 'FETCH_REPORTS_TOP_SITES_DATA_GRAPH',
     SET_REPORTS_TOP_SITES_DATA_GRAPH: 'SET_REPORTS_TOP_SITES_DATA_GRAPH',

     FETCH_REPORTS_CAMPAIGN_LIST: 'FETCH_REPORTS_CAMPAIGN_LIST',
     SET_REPORTS_CAMPAIGN_LIST: 'SET_REPORTS_CAMPAIGN_LIST',

     FETCH_REPORTS_SITE_LIST: 'FETCH_REPORTS_SITE_LIST',
     SET_REPORTS_SITE_LIST: 'SET_REPORTS_SITE_LIST',

     FETCH_REPORTS_ALL_CAMP_SITES_LIST: 'FETCH_REPORTS_ALL_CAMP_SITES_LIST',
     SET_REPORTS_ALL_CAMP_SITES_LIST: 'SET_REPORTS_ALL_CAMP_SITES_LIST',

     FETCH_CAMP_REPORTS_COUNTRIES_TABLE: 'FETCH_CAMP_REPORTS_COUNTRIES_TABLE',
     SET_CAMP_REPORTS_COUNTRIES_TABLE: 'SET_CAMP_REPORTS_COUNTRIES_TABLE',

     FETCH_CAMP_REPORTS_DEVICES_TABLE: 'FETCH_CAMP_REPORTS_DEVICES_TABLE',
     SET_CAMP_REPORTS_DEVICES_TABLE: 'SET_CAMP_REPORTS_DEVICES_TABLE',

     FETCH_CAMP_REPORTS_MEDIA_TABLE: 'FETCH_CAMP_REPORTS_MEDIA_TABLE',
     SET_CAMP_REPORTS_MEDIA_TABLE: 'SET_CAMP_REPORTS_MEDIA_TABLE',

     FETCH_CAMP_REPORTS_SITES_TABLE: 'FETCH_CAMP_REPORTS_SITES_TABLE',
     SET_CAMP_REPORTS_SITES_TABLE: 'SET_CAMP_REPORTS_SITES_TABLE',

     FETCH_CAMP_REPORTS_CREATIVE_TABLE: 'FETCH_CAMP_REPORTS_CREATIVE_TABLE',
     SET_CAMP_REPORTS_CREATIVE_TABLE: 'SET_CAMP_REPORTS_CREATIVE_TABLE',

     FETCH_REAL_TIME_REPORTS_IMP_CLICK_GRAPH: 'FETCH_REAL_TIME_REPORTS_IMP_CLICK_GRAPH',
     SET_REAL_TIME_REPORTS_IMP_CLICK_GRAPH: 'SET_REAL_TIME_REPORTS_IMP_CLICK_GRAPH',
}
export default reportTypes;

export interface TabsPerformanceTable {
     name: String;
     eCPM: number;
     CTR: number;
     ad_impressions: number;
     budget_spent: number;
}

export interface reportTopCardsType {
     budget_percentage?: number;
     campaign_type?: string;
     created_on?: string;
     end_date?: string;
     live_on?: string;
     new_CTR?: number;
     new_ad_impression?: number;
     new_budget_spent?: number;
     new_eCPM?: number;
     old_CTR?: number;
     old_ad_impression?: number;
     old_budget_spent?: number;
     old_eCPM?: number;
     percentage_CTR?: number;
     percentage_ad_impression?: number;
     percentage_budget_spent?: number;
     percentage_eCPM?: number;
     rate?: number | string;
     status?: string;
     total_budget?: number;
}

export interface ReportGeneralReport {
     start_dt?: string,
     end_dt?: string,
     value?:string,
     time_interval?:string | number,
}

export interface ReportGraphData {
     date?: string;
     impressions?: number;
     acquisitions?: number;
     clicks?: number;
     from?: number;
     to?: number;
     imp?: any;
     impClick: any;
     impAcq: any;
     data?:any;
     totalBudget?:any;
     totalCtr?:any;
}

export interface OverallReportState {
     overallReportTopCards?: reportTopCardsType;
     overallReportTopCardsLoader: boolean;

     budgetSpentReportData: any;
     budgetSpentReportLoader: boolean;
     totalBudgetSpend: any;
     isHourBudgetInterval:boolean;

     ctrReportData: any;
     ctrReportLoader: boolean;
     totalCtr: any

     impGraphData?: ReportGraphData[];
     impClickGraphData?: ReportGraphData[];
     impAcqGraphData?: ReportGraphData[];
     impClickAcqGraphLoader: boolean;
     impClickAcqGraphFrom: string | null;
     impClickAcqGraphTo: string | null;



     topCountriesImpData: any;
     topCountriesClickData: any;
     topCountriesDataLoader: boolean;
     topDevicesImpData: any;
     topDevicesClickData: any;
     topDevicesDataLoader: boolean;
     topMediaSizesImpData: any;
     topMediaSizesClickData: any;
     topMediaSizesDataLoader: boolean;
     topSitesImpData: any;
     topSitesClickData: any;
     topSitesDataLoader: boolean;
     reportsCampeignList: any;
     reportsCampeignListLoader: boolean;
     totalCampeignListRecords: number;

     reportsSiteList: any,
     reportsSiteListLoader: boolean,
     totalSiteListRecords: number,

     reportsAllCampSitesList: any,
     reportsAllCampSitesListLoader: boolean,

     campReportsTopCountriesTableData: any;
     campReportsTopCountriesTableRecordCount: number;
     campReportsTopCountriesTableLoader: boolean;

     campReportsTopDevicesTableData: any;
     campReportsTopDevicesTableRecordCount: number;
     campReportsTopDevicesTableLoader: boolean;

     campReportsTopMediaTableData: any;
     campReportsTopMediaTableRecordCount: number;
     campReportsTopMediaTableLoader: boolean;

     campReportsTopSitesTableData: any;
     campReportsTopSitesTableRecordCount: number;
     campReportsTopSitesTableLoader: boolean;

     campReportsTopCreativeTableData: any;
     campReportsTopCreativeTableRecordCount: number;
     campReportsTopCreativeTableLoader: boolean;

     realTimeImpClickGraphData: any;
     realTimeImpAcqGraphData: any;
     realTimeImpClickAcqGraphLoader: boolean;
}
